import React, { useState } from "react";

import { useAppContext, useAuthContext } from "../../../Providers";

import axios from "axios";
//Styles
import "./style.scss";

//Assets

const Login = () => {
  const { setIsLoggedIn } = useAuthContext();
  const { createNotification, setIsLoading } = useAppContext();

  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const login = async () => {
    try {
      if (!username) {
        return createNotification("يجب كتابة اسم المستخدم ", "warning");
      }
      if (!password) {
        return createNotification("يجب كتابة كلمة المرور", "warning");
      }

      let response = await axios.post("/api/auth/adminLogin", {
        username,
        password,
      });

      setIsLoading(true);

      console.log(response);

      let data = await response.data;

      console.log(data.message);
      console.log(data.status);

      if (!data.status) {
        createNotification(data.message, "error");
        return;
      }

      setIsLoggedIn(true);

      createNotification(data.message, "success");

      return;
    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="form-container">
        <form method="POST" onSubmit={(e) => e.preventDefault()}>
          <div className="input-container">
            <div className="title">
              <h3>اسم المستخدم</h3>
            </div>
            <div className="feild-container">
              <input
                type="text"
                placeholder="اسم المستخدم"
                value={username}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
          </div>
          <div className="input-container">
            <div className="title">
              <h3>كلمة المرور</h3>
            </div>
            <div className="feild-container">
              <input
                type="password"
                placeholder="كلمة المرور"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="btn-container">
            <button type="submit" onClick={() => login()}>
              تسجيل الدخول
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
