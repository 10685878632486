import React, { useState, useEffect, useRef } from "react";
import useAddServerHook from "./hooks";
import { useOnClickOutside } from "../../../hooks";

//Styles
import "./style.scss";

const AddServer = ({ visible, setVisible }) => {
  const { addserver } = useAddServerHook();
  const [fileData, setFileData] = useState([]);
  const [serverObj, setServerObj] = useState({
    serverName: "",
    serverIP: "",
    maxUsers: "",
    configFile: {},
  });

  useEffect(() => {
    if (visible)
      setServerObj({
        serverName: "",
        serverIP: "",
        maxUsers: "",
        configFile: {},
      });
  }, [visible]);

  const formRef = useRef(null);

  const floatingBoxRef = useRef(null);

  useOnClickOutside(floatingBoxRef, () => setVisible(false));
  return (
    visible && (
      <div className="add-server-container">
        <div className="form-container" ref={floatingBoxRef}>
          <div className="closing" onClick={() => setVisible(false)}>
            <span></span>
            <span></span>
          </div>
          <form
            method="POST"
            onSubmit={async (e) => {
              e.preventDefault();

              const file = await addserver(formRef);
              if (file) {
                setFileData([...fileData, file]);
                setVisible(false);
                window.location.reload();
              }
            }}
            ref={formRef}
          >
            <div className="input-container">
              <label>اسم الخادم</label>
              <input
                type="text"
                name="serverName"
                placeholder="United States"
                onChange={(e) =>
                  setServerObj({ ...serverObj, serverName: e.target.value })
                }
              />
            </div>
            <div className="input-container">
              <label>عنوان الخادم</label>
              <input
                type="text"
                name="serverIP"
                placeholder="186.135.12.18"
                onChange={(e) =>
                  setServerObj({ ...serverObj, serverIP: e.target.value })
                }
              />
            </div>
            <div className="input-container">
              <label>أقصي عدد يتحمله الخادم</label>
              <input
                type="number"
                name="maxUsers"
                placeholder="أقصي عدد يتحمله الخادم"
                onChange={(e) =>
                  setServerObj({ ...serverObj, maxUsers: e.target.value })
                }
              />
            </div>
            <div className="input-container file-upload">
              <label>رفع الملف</label>
              <div>
                <input
                  id="upload"
                  name="configFile"
                  type="file"
                  accept=".ovpn"
                  onChange={(e) =>
                    setServerObj({
                      ...serverObj,
                      configFile: e.target.files[0],
                    })
                  }
                />
              </div>
            </div>

            <div className="btn-container">
              <button type="submit">إضافة</button>
            </div>
          </form>
        </div>
      </div>
    )
  );
};

export default AddServer;
