import React from "react";
import { FaBan } from "react-icons/fa";

//Styles
import "./style.scss";

//Assets

const TableRow = ({ index, review, comment, deviceType }) => {
  return (
    <tr>
      <td>{index + 1}</td>
      <td>{comment}</td>
      <td>{deviceType}</td>
      <td>{review}</td>
    </tr>
  );
};

export default TableRow;
