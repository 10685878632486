import React, { useState } from "react";

import { IoMdPersonAdd } from "react-icons/io";

import { TableRow, AddAdmin } from "./components";

//Styles
import "./style.scss";

//Assets

const Admins = () => {
  const [visible, setVisible] = useState(false);

  return (
    <div className="admins-container">
      <div className="title">
        <h2>المشرفين</h2>
      </div>

      <div className="add-admin">
        <button onClick={() => setVisible(true)}>
          إضافة مشرف
          <IoMdPersonAdd />
        </button>
      </div>
      <div className="floating-box-container">
        <AddAdmin visible={visible} setVisible={setVisible} />
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>إسم المستخدم</th>
              <th>كلمة المرور</th>
              <th>حذف</th>
            </tr>
          </thead>
          <tbody>
            {[1, 2, 3, 4, 5].map((item, index) => (
              <TableRow index={index} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Admins;
