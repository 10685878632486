import React from "react";
import { useAuthContext } from "./Providers";

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import {
  Admins,
  LandingPage,
  Login,
  Messages,
  PrivacyAndPolicy,
  Servers,
  TermsOfServices,
  Users,
  UsersComments,
} from "./router";

import { Header, Loader } from "./components";

//Styles
import "./style.scss";

function App() {
  const { isLoggedIn } = useAuthContext();

  return (
    <div className="app-container">
      <Loader />
      <Router>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/privacy-policy" component={PrivacyAndPolicy} />
          <Route exact path="/terms-of-services" component={TermsOfServices} />
          <Route
            exact
            path="/admin"
            component={() => (
              <Redirect to={isLoggedIn ? "/admin/users" : "/admin/login"} />
            )}
          />
          {isLoggedIn ? (
            <>
              <Header />
              <Switch>
                <Route exact path="/admin/users" component={Users} />
                <Route exact path="/admin/admins" component={Admins} />
                <Route exact path="/admin/servers" component={Servers} />
                <Route
                  exact
                  path="/admin/users-comments"
                  component={UsersComments}
                />
                <Route exact path="/admin/messages" component={Messages} />
                <Redirect to="/admin/users" />
              </Switch>
            </>
          ) : (
            <Switch>
              <Route exact path="/admin/login" component={Login} />
              <Redirect to="/admin/login" />
            </Switch>
          )}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
