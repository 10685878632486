import React, { useState, useEffect } from "react";

//hooks
import useMessagesHook from "./hooks";

import { TableRow } from "./components";
//Styles
import "./style.scss";

//Assets

const Messages = () => {
  const { getHelpMessages } = useMessagesHook();
  const [messagesData, setMessagesData] = useState([]);

  useEffect(() => {
    (async () => {
      const data = await getHelpMessages();
      if (data) {
        setMessagesData(...messagesData, data.data);
      }
    })();
  }, []);

  return (
    <div className="messages-container">
      <div className="title">
        <h2>الرسائل</h2>
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>الرسالة</th>
              <th>نوع الهاتف</th>
              <th>الاسم</th>
              <th>الإيميل</th>
            </tr>
          </thead>
          <tbody>
            {messagesData &&
              messagesData.map((message, index) => (
                <TableRow index={index} {...message} />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Messages;
