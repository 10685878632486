import React from "react";
import { NavLink } from "react-router-dom";

import { motion } from "framer-motion";

//Styles
import "./style.scss";
//Assets
import { ReactComponent as Logo } from "../../../assets/images/LOGO.svg";

const NavBar1 = ({ visible, setVisible }) => {
  return (
    <div className="navbar-container">
      <div className="left">
        <motion.div
          className="logo-container"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.2 }}
        >
          <Logo /> <span>FLAME VPN</span>
        </motion.div>
        <motion.div
          className="navs-container"
          initial={{ y: -250 }}
          animate={{ y: 0 }}
        >
          <NavLink activeClassName="active" exact to="/" whileH>
            HOME
          </NavLink>
          <NavLink activeClassName="active" to="/privacy-policy">
            PRIVACY&POLICY
          </NavLink>
          <NavLink activeClassName="active" to="/terms-of-services">
            TERMS OF SERVICES
          </NavLink>
        </motion.div>
      </div>
      <motion.div
        className="burger-menu"
        // style={{ marginRight: visible ? "250px" : "0" }}
        onClick={() => setVisible(!visible)}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.2 }}
      >
        <span></span>
        <span className="mid">
          <span></span>
          <span className="right"></span>
        </span>
        <span></span>
      </motion.div>
    </div>
  );
};

export default NavBar1;
