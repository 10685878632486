import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import { useOnClickOutside } from "../../../hooks";

//Styles
import "./style.scss";

//Assets
import { ReactComponent as Terms } from "../../../assets/images/terms.svg";
import { ReactComponent as Privacy } from "../../../assets/images/privacy.svg";

const SideBar = ({ visible, setVisible }) => {
  const sidebarRef = useRef(null);
  useOnClickOutside(sidebarRef, () => setVisible(false));

  return (
    visible && (
      <div
        className="sidebar-container"
        style={{ right: visible ? "0" : "-1000px" }}
        ref={sidebarRef}
      >
        <div>
          <div className="routes">
            <NavLink exact activeClassName="active" to="/">
              <div>
                <AiFillHome />
              </div>
              Home
            </NavLink>
            <NavLink activeClassName="active" to="/privacy-policy">
              <div>
                <Terms />
              </div>
              PRIVACY&POLICY
            </NavLink>
            <NavLink activeClassName="active" to="/terms-of-services">
              <div>
                <Privacy />
              </div>
              TERMS OF SERVICES
            </NavLink>
          </div>
        </div>
      </div>
    )
  );
};

export default SideBar;
