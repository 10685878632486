import axios from "axios";
import { useAppContext } from "../../../../Providers";

const useAddAdminHook = () => {
  const { createNotification, setIsLoading } = useAppContext();

  const addAdmin = async (Data = {}) => {
    console.log(Data.username);
    try {
      //Validation
      if (!Data.username) {
        createNotification("يجب كتابة اسم المستخدم", "warning");
        return { status: false };
      }

      if (!Data.password) {
        createNotification("يجب كتابة كلمة المرور", "warning");
        return { status: false };
      }
      if (!Data.passwordConfirm) {
        createNotification("يجب تأكيد كلمة المرور", "warning");
        return { status: false };
      }

      /************************************/
      setIsLoading(true);

      let response = await axios.post("/api/addAdmin", Data);

      let data = await response.data;

      if (!data.status) {
        createNotification(data.message);
        return { status: false };
      }

      createNotification(data.message, "success");
      return data;
    } catch (e) {
      createNotification(e.message);
      return { status: false };
    } finally {
      setIsLoading(false);
    }
  };

  return { addAdmin };
};

export default useAddAdminHook;
