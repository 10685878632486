import React from "react";

//Styles
import "./style.scss";

//Assets

const TableRow = ({ index, message, deviceType, fullName, email }) => {
  return (
    <tr>
      <td>{index + 1}</td>
      <td>{message}</td>
      <td>{deviceType}</td>
      <td>{fullName}</td>
      <td>{email}</td>
    </tr>
  );
};

export default TableRow;
