import React, { useState, useRef } from "react";
import { Link, NavLink } from "react-router-dom";

import { BsPersonPlusFill } from "react-icons/bs";
import { FaServer, FaComments, FaUser } from "react-icons/fa";
import { IoLogOut } from "react-icons/io5";
import { AiFillMessage } from "react-icons/ai";
import { RiAdminFill } from "react-icons/ri";

import { useAuthContext, useAppContext } from "../../../Providers";

import { useOnClickOutside } from "../../../hooks";

//Style
import "./Style.scss";
import { AddServer } from "../../index";

const Header = () => {
  const [sidebarActive, setSidebarActive] = useState(false);
  const [visible, setVisible] = useState(false);

  const { setIsLoggedIn } = useAuthContext();
  const { createNotification } = useAppContext();

  const sidebarRef = useRef(null);

  useOnClickOutside(sidebarRef, () => setSidebarActive(false));

  return (
    <div className="header-container">
      <div className="head">
        <div
          className="burger-menu"
          onClick={!sidebarActive ? () => setSidebarActive(true) : null}
          style={{ marginRight: sidebarActive ? "260px" : "0" }}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div
        className="sidebar"
        style={{ right: sidebarActive ? "0" : "-1000px" }}
        ref={sidebarRef}
      >
        <div>
          <div className="add-user-btn" onClick={() => setVisible(true)}>
            <button>
              <BsPersonPlusFill />
              إضافة خادم
            </button>
          </div>
          <div className="routes">
            <NavLink activeClassName="active" to="/admin/users">
              <FaUser />
              المستخدمين
            </NavLink>
            <NavLink activeClassName="active" to="/admin/admins">
              <RiAdminFill />
              المشرفين
            </NavLink>
            <NavLink activeClassName="active" to="/admin/servers">
              <FaServer />
              الخوادم
            </NavLink>
            <NavLink activeClassName="active" to="/admin/users-comments">
              <FaComments />
              تعليقات المستخدمين
            </NavLink>
            <NavLink activeClassName="active" to="/admin/messages">
              <AiFillMessage />
              الرسائل
            </NavLink>
          </div>
        </div>
        <div className="logout-button">
          <button
            to="/"
            onClick={() => {
              createNotification("تم تسجيل الخروج بنجاح", "info");
              setIsLoggedIn(false);
            }}
          >
            <IoLogOut />
            تسجيل الخروج
          </button>
        </div>
      </div>

      <div className="floating-box-container">
        <AddServer visible={visible} setVisible={setVisible} />
      </div>
    </div>
  );
};

export default Header;
