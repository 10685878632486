import { get } from "mongoose";
import React, { useEffect, useState } from "react";

import { TableRow } from "./components";

import useCommentsHook from "./hooks";

//Styles
import "./style.scss";

//Assets

const UsersComments = () => {
  const { getReviews } = useCommentsHook();
  const [reviewsData, setReviewsData] = useState([]);

  useEffect(() => {
    (async () => {
      const data = await getReviews();
      if (data) {
        setReviewsData(...reviewsData, data.data);
      }
    })();
  }, []);

  return (
    <div className="users-comments-container">
      <div className="title">
        <h2>تعليقات المستخدمين</h2>
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>التعليق</th>
              <th>نوع الهاتف</th>
              <th>التقييم</th>
            </tr>
          </thead>
          <tbody>
            {reviewsData &&
              reviewsData.map((review, index) => (
                <TableRow {...review} index={index} />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UsersComments;
