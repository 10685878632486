import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

//Styles
import "./style.scss";

const Footer = () => {
  return (
    <div className="footer-container">
      <div> Copyright &copy; {new Date().getFullYear()} FLAME VPN </div>
      <div className="quick-navs">
        <Link>Privacy & Policy</Link> | <Link>Terms of Service</Link> |{" "}
        <Link>Contact</Link>
      </div>
    </div>
  );
};

export default Footer;
