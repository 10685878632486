import React from "react";

import { FaFileDownload } from "react-icons/fa";

//Styles
import "./style.scss";

//Assets

const TableRow = ({ serverName, serverIP, maxUsers, configFile, index }) => {
  return (
    <tr>
      <td>{index + 1}</td>
      <td>{serverName}</td>
      <td>{serverIP}</td>
      <td>{maxUsers}</td>
      <td className="file">
        <a href={configFile}>
          تحميل الملف
          <FaFileDownload />
        </a>
      </td>
    </tr>
  );
};

export default TableRow;
