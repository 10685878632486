import React, { useState, useEffect } from "react";

import { TableRow } from "./components";

//Hooks
import useServerHook from "./hooks";

//Styles
import "./style.scss";

//Assets

const Servers = () => {
  const { getServer } = useServerHook();
  const [serversData, setServersData] = useState([]);

  useEffect(() => {
    (async () => {
      const data = await getServer();

      if (data) {
        setServersData(...serversData, data.data);
      }
    })();
  }, []);

  return (
    <div className="servers-container">
      <div className="title">
        <h2>الخوادم</h2>
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>اسم الخادم</th>
              <th>عنوان الخادم</th>
              <th>اقصي عدد يتحملة</th>
              <th>الملف</th>
            </tr>
          </thead>
          <tbody>
            {serversData &&
              serversData.map((server, index) => (
                <TableRow index={index} {...server} />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Servers;
