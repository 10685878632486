import axios from "axios";
import { useAppContext } from "../../../../Providers";

const useServerHook = () => {
  const { createNotification, setIsLoading } = useAppContext();

  const getServer = async () => {
    try {
      setIsLoading(true);
      let response = await axios.post("/api/getServers");
      let data = response.data;

      if (!data.status) {
        createNotification(data.message);
        return data;
      }
      createNotification(data.message, "success");
      return data;
    } catch (e) {
      createNotification(e.message);
      return { status: false };
    } finally {
      setIsLoading(false);
    }
  };

  return { getServer };
};

export default useServerHook;
