import React, { isValidElement, useState } from "react";
import { NavLink } from "react-router-dom";

import { Footer, Poster, SideBar, NavBar } from "../../../components";
import { motion } from "framer-motion";

//Styles
import "./style.scss";

//Assets
import { ReactComponent as Time } from "../../../assets/images/time.svg";
import { ReactComponent as Free } from "../../../assets/images/free.svg";
import { ReactComponent as Android } from "../../../assets/images/android.svg";
import { ReactComponent as IOS } from "../../../assets/images/apple.svg";

const LandingPage = () => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="landing-page-container">
      <NavBar visible={isVisible} setVisible={setIsVisible} />
      <div className="container">
        <div className="content">
          <div className="text">
            <motion.p
              initial={{ x: "-100vw" }}
              animate={{ x: 0 }}
              transition={{ delay: 0.5, duration: 0.5, type: "tween" }}
            >
              One of the Best VPN Service Provider In {new Date().getFullYear()}
            </motion.p>
            <motion.a
              href="#download"
              initial={{ x: "100vw" }}
              animate={{ x: 0 }}
              transition={{ delay: 0.5, duration: 0.5, type: "tween" }}
            >
              DOWNLOAD
            </motion.a>
          </div>
          <div className="services">
            <div className="time">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1.5 }}
              >
                <Time />
              </motion.div>
              <motion.p
                initial={{ x: "-100vw" }}
                animate={{ x: 0 }}
                transition={{ delay: 0.8, duration: 1.5 }}
              >
                Fast VPN Without <br /> Third Parties
              </motion.p>
            </div>
            <div className="free">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1.5 }}
              >
                <Free />
              </motion.div>
              <motion.p
                initial={{ x: "-100vw" }}
                animate={{ x: 0 }}
                transition={{ delay: 0.5, duration: 1.5 }}
              >
                Absolutely <br /> Free VPN
              </motion.p>
            </div>
          </div>
        </div>
      </div>
      <Poster />
      <div className="download" id="download">
        <motion.div
          className="android"
          initial={{ y: "100vh" }}
          animate={{ y: 0 }}
          transition={{ delay: 0.5, duration: 1, type: "tween" }}
        >
          <div>
            <Android />
          </div>
          <div>
            <motion.button whileHover={{ scale: 1.2 }}>DOWNLOAD</motion.button>
          </div>
        </motion.div>
        <motion.div
          className="ios"
          initial={{ y: "100vh" }}
          animate={{ y: 0 }}
          transition={{ delay: 0.8, duration: 1 }}
        >
          <div>
            <IOS />
          </div>
          <div>
            <span>Soon...</span>
          </div>
        </motion.div>
      </div>
      <SideBar visible={isVisible} setVisible={setIsVisible} />
      <Footer />
    </div>
  );
};

export default LandingPage;
