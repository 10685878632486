import React, { useState, useEffect, useRef } from "react";

import { useOnClickOutside } from "../../../../../hooks";

import useAddAdminHook from "../../hooks";

//Styles
import "./style.scss";

const AddAdmin = ({ visible, setVisible }) => {
  const { addAdmin } = useAddAdminHook();
  const [formObj, setFormObj] = useState({
    username: "",
    password: "",
    passwordConfirm: "",
  });
  useEffect(() => {
    if (visible)
      setFormObj({
        username: "",
        password: "",
        passwordConfirm: "",
      });
  }, [visible]);

  const floatingBoxRef = useRef(null);

  useOnClickOutside(floatingBoxRef, () => setVisible(false));

  return (
    visible && (
      <div className="add-admin-container">
        <div className="form-container" ref={floatingBoxRef}>
          <div className="closing" onClick={() => setVisible(false)}>
            <span></span>
            <span></span>
          </div>
          <form
            method="POST"
            onSubmit={async (e) => {
              e.preventDefault();

              const data = await addAdmin(formObj);
              if (data) {
                setVisible(false);
              }
            }}
          >
            <div className="input-container">
              <label>اسم المستخدم</label>
              <input
                type="text"
                name="username"
                placeholder="إسم المستخدم"
                onChange={(e) =>
                  setFormObj({ ...formObj, username: e.target.value })
                }
              />
            </div>
            <div className="input-container">
              <label>كلمة المرور</label>
              <input
                type="password"
                name="password"
                placeholder="كلمة المرور"
                onChange={(e) =>
                  setFormObj({ ...formObj, password: e.target.value })
                }
              />
            </div>
            <div className="input-container">
              <label>تأكيد كلمة المرور</label>
              <input
                type="password"
                name="passwordConfirm"
                placeholder="تأكيد كلمة المرور"
                onChange={(e) =>
                  setFormObj({ ...formObj, passwordConfirm: e.target.value })
                }
              />
            </div>
            <div className="btn-container">
              <button type="submit">إضافة</button>
            </div>
          </form>
        </div>
      </div>
    )
  );
};

export default AddAdmin;
