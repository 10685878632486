import React from "react";
import { FaBan } from "react-icons/fa";

//Styles
import "./style.scss";

//Assets

const TableRow = ({ index }) => {
  return (
    <tr>
      <td>{index}</td>
      <td>MomenAyman</td>
      <td>123456</td>
      <td className="action">
        <button>
          حذف
          <FaBan />
        </button>
      </td>
    </tr>
  );
};

export default TableRow;
