import React from "react";
import { FaBan } from "react-icons/fa";

//Styles
import "./style.scss";

//Assets

const TableRow = () => {
  return (
    <tr>
      <td>1</td>
      <td>IOS</td>
      <td>United States</td>
      <td>3h 4min</td>
      <td>2:00 pm 13/04/2021</td>
      <td className="action">
        <button>
          <div> حظر المستخدم</div>
          <FaBan />
        </button>
      </td>
    </tr>
  );
};

export default TableRow;
