import React from "react";

import { motion } from "framer-motion";

//Styles
import "./style.scss";

//Assets
import { ReactComponent as PosterSvg } from "../../../assets/images/poster-svg.svg";

const Poster = () => {
  return (
    <motion.div
      className="poster-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
    >
      <motion.div
        className="poster-icon"
        initial={{ y: "-100vh" }}
        animate={{ y: 0 }}
        transition={{ delay: 0.5, duration: 1.5 }}
      >
        <PosterSvg />
      </motion.div>
      <motion.div
        className="text"
        initial={{ y: "100vh" }}
        animate={{ y: 0 }}
        transition={{ delay: 0.5, duration: 1.5 }}
      >
        <h2>The all-access pass to global content</h2>
        <p>
          Get your favorite shows and apps wherever you are, and stay up to date
          even when you’re far from home. Say goodbye to censorship and
          restrictions.
        </p>
      </motion.div>
    </motion.div>
  );
};

export default Poster;
