import React from "react";

import { TableRow } from "./components";

//Styles
import "./style.scss";

//Assets

const Users = () => {
  return (
    <div className="users-container">
      <div className="title">
        <h2>المستخدمين</h2>
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>نوع الهاتف</th>
              <th>الخادم</th>
              <th>الجلسة الأخيرة</th>
              <th>أخر تواجد</th>
              <th>الخيارات</th>
            </tr>
          </thead>
          <tbody>
            {[1, 2, 3, 4, 5].map((item, index) => (
              <TableRow />
            ))}
          </tbody>
        </table>{" "}
      </div>
    </div>
  );
};

export default Users;
