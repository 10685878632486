import React, { isValidElement, useState } from "react";
import { NavLink } from "react-router-dom";

import { Footer, Poster, SideBar, NavBar } from "../../../components";
import { motion } from "framer-motion";

//Styles
import "./style.scss";

//Assets

const PrivacyAndPolicy = () => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="privacy-policy-container">
      <NavBar visible={isVisible} setVisible={setIsVisible} />
      <div className="container">
        <div className="content">
          <div className="title">
            <motion.h2
              initial={{ x: "-100vw" }}
              animate={{ x: 0 }}
              transition={{ delay: 0.5, duration: 0.5, type: "tween" }}
            >
              PRIVACY AND POLICY
            </motion.h2>
          </div>
          <motion.div
            className="details"
            initial={{ x: "100vw" }}
            animate={{ x: 0 }}
            transition={{ delay: 0.8, duration: 0.5, type: "tween" }}
          >
            There is a text here for the details of this section. There is a
            text here for the details of this section. There is a text here for
            the details of this section. There is a text here for the details of
            this section. There is a text here for the details of this section.
            There is a text here for the details of this section. There is a
            text here for the details of this section. There is a text here for
            the details of this section. There is a text here for the details of
            this section. There is a text here for the details of this section.
            There is a text here for the details of this section.
          </motion.div>
        </div>
      </div>
      <Poster />

      <SideBar visible={isVisible} setVisible={setIsVisible} />
      <Footer />
    </div>
  );
};

export default PrivacyAndPolicy;
