import axios from "axios";
import { useAppContext } from "../../../../Providers";

const useAddServerHook = () => {
  const { createNotification } = useAppContext();

  const addserver = async (formRef) => {
    try {
      let fileData = new FormData(formRef.current);

      //Validation
      if (!fileData.get("serverName")) {
        createNotification("يجب كتابة اسم الخادم", "warning");
        return { status: false };
      }

      if (!fileData.get("serverIP")) {
        createNotification("يجب كتابة عنوان الخادم", "warning");
        return { status: false };
      }
      if (!fileData.get("maxUsers")) {
        createNotification("يجب كتابة أقصي عدد يتحمله الخادم", "warning");
        return { status: false };
      }
      if (!fileData.get("configFile")) {
        createNotification("يجب رفع الملف", "warning");
        return { status: false };
      }

      /************************************/
      let response = await axios.post("/api/addServer", fileData);

      let data = await response.data;

      console.log(data);

      if (!data.status) {
        createNotification(data.message);
        return { status: false };
      }

      createNotification(data.message, "success");
      return data;
    } catch (e) {
      createNotification(e.message);
      return { status: false };
    }
  };

  return { addserver };
};

export default useAddServerHook;
